@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: Jost-Regular;
	src: url('fonts/Jost-Regular.ttf');
}

@font-face {
	font-family: Jost-SemiBold;
	src: url('fonts/Jost-SemiBold.ttf');
}

.font-regular {
	font-family: Jost-Regular;
}

.font-bold {
	font-family: Jost-SemiBold;
}

.custom-scrollbar::-webkit-scrollbar {
	height: 8px; /* Adjust the height of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #888; /* Color of the scrollbar thumb */
	border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Color of the scrollbar track */
}

.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.hide-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}

body ::-webkit-scrollbar {
	width: 0 !important;
}

body {
	overflow: -moz-scrollbars-none;
}

body {
	-ms-overflow-style: none;
}

body {
	margin: 0;
	font-size: 16px;
	font-family:
		Jost-Regular,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
